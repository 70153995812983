<template>
  <v-card :loading="loading">
    <v-card-title class="px-0 pb-0">
      <v-container fluid class="px-0 py-0 mb-2">
        <v-row align="end" class="px-8">
          <v-col cols="auto">
            <v-btn
              height="29"
              class="label text-capitalize font-weight-regular"
              depressed
              color="secondary"
              :ripple="false"
            >
              {{ $t('page_company_sales_title') }}
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <h3 class="text-period main--text font-weight-regular mb-0">
              {{ $t('monthly_accounting') }} | {{ term }}
            </h3>
          </v-col>

          <v-col cols="auto" class="pl-0">
            <h5 class="text-date font-weight-regular mb-0">
              {{ $store.getters.getCompanyToday }}
            </h5>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="auto">
            <v-btn
              icon
              @click="toggleExpand"
              :class="isExpanded ? 'rotate' : ''"
            >
              <v-icon size="16">$customChevronDown</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text v-if="noData">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-img
              :src="require('@/assets/images/no-data-found.svg')"
              max-height="100"
              contain
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-text v-if="!noData" class="pb-0">
      <v-container fluid>
        <v-row class="px-8" align="end">
          <v-col cols="3" class="py-0">
            <v-row align="center">
              <v-col cols="3" class="text-right">
                <div class="main--text text-subheading-2 font-weight-regular">
                  {{ $t('total_sales') }}
                </div>
              </v-col>
              <v-col cols="auto">
                <div class="main--text text-heading font-weight-medium">
                  {{ sales.total_amount | toCurrency }}
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="3" class="py-0" v-if="sales.supposed">
            <v-row align="center">
              <v-col cols="3" class="text-right">
                <div
                  class="text-subheading-2 font-weight-regular text-right info--text"
                >
                  {{ $t('supposed') }}
                </div>
              </v-col>
              <v-col cols="auto">
                <div class="text-subheading font-weight-regular info--text">
                  {{ sales.supposed.amount | toCurrency }}
                </div>
              </v-col>
              <v-col cols="auto">
                <v-chip
                  class="font-weight-regular chip-custom"
                  small
                  color="rgba(0, 148, 255, 0.15)"
                  text-color="info"
                  :ripple="false"
                >
                  {{ sales.supposed.percentage }}%
                </v-chip>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="3" class="py-0" v-if="sales.goal">
            <v-row align="center">
              <v-col cols="3" class="text-right">
                <div
                  class="text-subheading-2 font-weight-regular text-right info--text"
                >
                  {{ $t('goal') }}
                </div>
              </v-col>
              <v-col cols="auto">
                <div class="text-subheading font-weight-regular info--text">
                  {{ sales.goal.amount | toCurrency }}
                </div>
              </v-col>
              <v-col cols="auto">
                <v-chip
                  class="font-weight-regular chip-custom"
                  small
                  color="rgba(0, 148, 255, 0.15)"
                  text-color="info"
                  :ripple="false"
                >
                  {{ sales.goal.percentage }}%
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-text v-if="!noData" class="px-0">
      <v-expansion-panels v-model="expanded" accordion class="elevation-0">
        <v-expansion-panel :key="0">
          <v-expansion-panel-content eager>
            <Table
              :headers="headers"
              :items="tableItems"
              :footer="false"
              :total-records="tableItemsLength"
              :number-of-pages="0"
            >
              <template v-slot:item="{ item, index }">
                <tr class="text-center">
                  <template v-if="index === 0">
                    <td colspan="2" class="font-weight-bold">{{ $t('sum') }}</td>
                  </template>

                  <template v-else>
                    <td>
                      <span class="primary--text">
                        {{ item.account_item.name }}
                        <v-badge
                          v-if="item.account_item.type"
                          color="primary"
                          class="pl-2 white--text"
                          :content="item.account_item.type"
                        >
                        </v-badge>
                      </span>
                    </td>

                    <td>
                      <v-sparkline
                        :value="item.graph"
                        color="primary"
                        height="100"
                        padding="24"
                        stroke-linecap="round"
                        :smooth="24"
                        auto-draw
                      >
                      </v-sparkline>
                    </td>

                    <!--                    <td>-->
                    <!--                      {{ item.sum | toCurrency }}-->
                    <!--                    </td>-->
                  </template>

                  <td
                    v-for="header in filteredHeaders"
                    :key="`header-${header.value}-${index}`"
                  >
                    {{ item[header.value] }}
                  </td>
                </tr>
              </template>
            </Table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'

export default {
  name: 'Sales',
  components: { Table },
  props: {
    term: {
      type: String,
      default: '',
      required: false
    },
    sales: {
      type: Object,
      default: () => {},
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  created() {},
  data() {
    return {
      expanded: 0,
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Des'
      ],
      headers: [
        {
          text: this.$t('items'),
          align: 'center',
          itemAlign: 'right',
          value: 'account_item',
          sortable: false,
          width: 110
        },
        {
          text: this.$t('graph'),
          align: 'center',
          value: 'graph',
          sortable: false,
          width: 110
        }
        // {text: 'Sum Data', align: 'center', value: 'sum', sortable: true, width: 100},
      ]
    }
  },
  computed: {
    isExpanded() {
      return this.expanded === 0
    },
    noData() {
      return this.sales?.account_item_deals?.length === 0
    },
    filteredHeaders() {
      return this.headers.filter(
        item => item.value !== 'account_item' && item.value !== 'graph'
      )
    },
    tableItems() {
      if (this.sales.account_item_deals === undefined) return []
      let items = this.sales?.account_item_deals?.map(item => {
        let monthlyValues = {}
        let graph = []
        // let sum = 0
        for (const [index, deal] of item.deals.entries()) {
          if (this.months[index] !== deal.month.name)
            this.months[index] = deal.month.name
          monthlyValues[this.months[index].toLowerCase()] =
            deal.amount === 0
              ? '--'
              : this.$options.filters.toCurrency(deal.amount)
          graph.push(deal.amount)
          // sum += deal.amount
        }
        return {
          account_item: item.account_item,
          graph: graph,
          // sum: sum,
          ...monthlyValues
        }
      })
      this.mapHeaders()

      let monthlySum = {}
      this.sales?.monthly_deals?.forEach(item => {
        monthlySum[item.month.name.toLowerCase()] =
          item.amount === 0
            ? '--'
            : this.$options.filters.toCurrency(item.amount)
      })

      items.unshift({
        account_item: null,
        graph: null,
        // sum: null,
        ...monthlySum
      })
      return items
    },
    tableItemsLength() {
      return this.tableItems?.length
    }
  },
  methods: {
    mapHeaders() {
      this.headers.length = 3
      let header = this.headers
      for (const month of this.months) {
        header.push({
          text: this.$t(month.toLowerCase()),
          align: 'center',
          value: `${month.toLowerCase()}`,
          width: 100,
          sortable: false
        })
      }
      this.headers = header
    },
    toggleExpand() {
      if (this.expanded === -1) {
        this.expanded = 0
      } else {
        this.expanded = -1
      }
    },
    getTdAlignClass(align) {
      return align !== null ? 'text-' + align : ''
    }
  }
}
</script>

<style lang="scss" src="./Sales.scss" scoped></style>
