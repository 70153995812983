<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-container fluid class="px-0 py-0">
            <v-row>
              <v-col cols="3">
                <v-select
                  :items="getCompanyBusinessYears"
                  item-value="term"
                  item-text="term"
                  v-model="selectedBusinessYear"
                  return-object
                  @change="getDataFromApi"
                  solo
                  flat
                  dense
                  class="term-select"
                  background-color="term-select"
                  color="primary"
                  hide-details
                  append-icon="$customChevronDown"
                >
                  <template v-slot:item="{ item }">
                    {{
                      $t('year_label', {
                        number: item.term,
                        start: item.start_date.slice(0, -3),
                        end: item.end_date.slice(0, -3)
                      })
                    }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{
                      $t('year_label', {
                        number: item.term,
                        start: item.start_date.slice(0, -3),
                        end: item.end_date.slice(0, -3)
                      })
                    }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <Sales
        :term="getTermLabel()"
        :loading="loading"
        :sales="getCompanySales"
      />
    </v-col>

    <v-col cols="12">
      <Cost
        :term="getTermLabel()"
        :loading="loading"
        :sales="getCompanyCosts"
      />
    </v-col>
  </v-row>
</template>

<script>
import Sales from '@/components/admin/partials/Company/Detail/SalesCost/Sales'
import Cost from '@/components/admin/partials/Company/Detail/SalesCost/Cost'
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  components: { Cost, Sales },
  created() {
    this.selectedBusinessYear = this.getCompanyBusinessYear
    this.getDataFromApi()
  },
  data() {
    return {
      loading: false,
      selectedBusinessYear: null
    }
  },
  computed: {
    ...mapGetters([
      'getCompanySales',
      'getCompanyCosts',
      'getCompanyBusinessYear',
      'getCompanyBusinessYears'
    ])
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      let companySales = this.$store.dispatch('COMPANY_SALES_GET', {
        id: this.$route.params.id,
        term: this.selectedBusinessYear?.term || ''
      })

      Promise.all([companySales]).finally(() => {
        this.loading = false
      })
    },

    getTermLabel() {
      if (this.selectedBusinessYear === null) {
        return ''
      }
      return this.$t('year_label', {
        number: this.selectedBusinessYear.term,
        start: this.selectedBusinessYear.start_date.slice(0, -3),
        end: this.selectedBusinessYear.end_date.slice(0, -3)
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
